<template>
    <div class="base-container">
        <el-card class="box-card">
            <!--仲裁评级选择（80-89）-->
            <div slot="header" class="clearfix" v-if="$store.state.teacherType!=2">
                <el-select v-model="query.scores"
                           placeholder="请选择评级"
                           style=" float: right;"
                           @change="onChangeRote"
                           clearable
                >
                    <el-option
                            v-for="(item, index) in $store.state.levelScoreRoteList"
                            :key="index"
                            :label="item.score"
                            :value="item.id"
                    ></el-option>
                </el-select>
            </div>
            <div class="item">
                <ul class="table-area" v-if="dataList.length>0">
                    <li class="grid-item" v-for="(item, index) in dataList" :key="index">
                        <div class="grid-box">
                            <div class="grid-content" @click="openGiveMark(item, index, $event)">
                                <el-image class="work-img" fit="contain" :src="$store.getters.getFilePath+item.images"
                                          lazy/>
                                <!--仲裁没有分数段显示（80-89）-->
                                <div class="rate-sign" v-if="$store.state.teacherType!=2">{{item.grade}}</div>
                                <div class="work-content">
                                    <div>作品编号：{{item.number}}</div>
                                    <!--<div>精度：{{item.precision}}</div>-->
                                    <div class="py_btn" @click="toGiveMark(item, index, $event)">评阅</div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <el-empty v-else class="empty-area" description="暂无数据"></el-empty>
                <el-pagination
                        v-if="total>0"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="query.start"
                        :page-sizes="[8, 12]"
                        :page-size="query.size"
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total">
                </el-pagination>
            </div>
        </el-card>
        <GiveMarkDialog
                :isTypeFu="3"
                :queryObjFu="queryGMObj"
                :dialogVisibleFu="dialogGMVisible"
                @closeDialog="closeDialog"
        />
    </div>
</template>

<script>
    import {mapMutations} from "vuex";
    import {getWaitList, getYjScoreLevel} from "../../api/wait";
    import GiveMarkDialog from "../../components/GiveMarkDialog";

    export default {
        name: "wait",
        components: {GiveMarkDialog},
        data() {
            return {
                query: {
                    start: 1,
                    size: 8,
                    scores: '',
                    order: 1,
                },
                total: 0,
                scoreRoteList: [],
                dataList: [],

                dialogGMVisible: false,
                queryGMObj: {},
                startCutDown: false, // true-评阅完成页数需要减一
            }
        },
        created() {
            let start = window.localStorage.getItem('wait_start');
            let size = window.localStorage.getItem('wait_size');
            let scores = window.localStorage.getItem('wait_scores');
            if (start && start != 0) {
                this.query.start = Number(start);
            }
            if (size && size != 0) {
                this.query.size = Number(size);
            }
            if (scores && scores != 0) {
                this.query.scores = Number(scores);
            }
            this.getList();
            if (!this.$store.state.levelScoreRoteList
                || this.$store.state.levelScoreRoteList.length <= 0) {
                this.getLevelScore();
            }
        },
        methods: {
            ...mapMutations([
                "updateLevelScoreRoteList",
            ]),
            // 选择分数等级
            onChangeRote(val) {
                this.query.scores = val;
                this.query.start = 1;
                window.localStorage.setItem('wait_scores', this.query.scores);
                window.localStorage.setItem('wait_start', this.query.start);
                this.getList(true);
            },
            // 关闭评阅弹框
            closeDialog(val, type) {
                this.dialogGMVisible = val;
                // 1-打分 2-分档打分
                if (type == 1) {
                    if (this.startCutDown) {
                        this.query.start--;
                        window.localStorage.setItem('wait_start', this.query.start);
                    }
                    this.getList();
                } else if (type == 2) {
                    this.getList();
                }
            },
            // 去评阅-图片
            openGiveMark(row, idx, event) {
                event.preventDefault();
                let start = (this.query.start - 1) * this.query.size + (idx + 1);
                let query = {
                    start: start,
                    order: this.query.order,
                }
                if (this.query.scores) {
                    query['scores'] = this.query.scores
                }
                this.queryGMObj = query;
                this.dialogGMVisible = true;
            },
            // 去评阅-按钮
            toGiveMark(data, idx, event) {
                event.stopPropagation();
                let start = (this.query.start - 1) * this.query.size + (idx + 1);
                let query = {
                    start: start,
                    order: this.query.order,
                }
                if (this.query.scores) {
                    query['scores'] = this.query.scores
                }

                this.$router.push({
                    path: '/wait/giveMark',
                    query: query
                });
                // window.localStorage.setItem('wait_start', '1');
                // window.localStorage.setItem('wait_size', '8');
                // window.localStorage.setItem('wait_scores', '0');
                window.localStorage.setItem('wait_size', this.query.size);
                window.localStorage.setItem('wait_scores', this.query.scores);
            },
            handleSizeChange(val) {
                this.query.size = val;
                window.localStorage.setItem('wait_size', this.query.size);
                this.getList();
            },
            handleCurrentChange(val) {
                this.query.start = val;
                window.localStorage.setItem('wait_start', this.query.start);
                this.getList();
            },
            // 获取分档数据列表
            getLevelScore() {
                let _this = this;
                getYjScoreLevel({})
                    .then(res => {
                        console.log(res)
                        if (res.data.code != 200) {
                            return _this.$message.error(res.data.message);
                        }
                        _this.updateLevelScoreRoteList(res.data.data.scores)
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            getList(type = false) {
                this.requestHandle(getWaitList)
            },
            requestHandle(api) {
                this.dataList = [];
                api(this.query)
                    .then(res => {
                        console.log(res)
                        if (res.data.code != 200) return this.$message.error(res.data.message)
                        this.total = res.data.data.sum ? res.data.data.sum : res.data.data.count;
                        this.dataList = res.data.data.list;

                        if (this.query.start > 1 && this.dataList.length == 1) {
                            this.startCutDown = true;
                        } else {
                            this.startCutDown = false;
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
        }
    }
</script>

<style scoped lang="scss">
    @import "../../assets/css/list";
</style>
